import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Animation extends Component {
    render() {
        return (
                <div style={{background: "#191919"}}>
                    <div className="vimeo-wrapper" >
                        <iframe style={{width:"100%"}} title="ANIMATION_VIDEO" src="https://player.vimeo.com/video/432509867?background=1" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen allow="autoplay"></iframe>
                    </div>

                    <Link to="/">
                        <img className="other_logo hide-on-med-and-down" src={require("../IMAGES/logo.png")} alt="LOADING" />
                        <img className="main_name hide-on-med-and-down" src={require("../IMAGES/name.png")} alt="LOADING" />

                        <img className="main_name_med hide-on-large-only show-on-medium hide-on-small-only" src={require("../IMAGES/name.png")} alt="LOADING" />

                        <img className="main_name_sm hide-on-large-only show-on-small hide-on-med-and-up" src={require("../IMAGES/name.png")} alt="LOADING" />
                    </Link>

                    <div className="ani_disp roboto_med white-text hide-on-med-and-down">
                            <h4>ANIMATION</h4>
                            <div className="line"></div>
                            <h6 className="justify">Animation has world’s undivided attention. Be it films, tutorials, gaming, business promotions, animation empowers all the sectors today. Shoonyeka is the apt place for all your animation needs. Unleash your imagination with us and watch them come alive with a framework that knows no bounds.</h6>
                    </div>

                    <div className="container line_change">

                        <div className="row valign-wrapper">

                            <div className="col s12 white-text hide-on-large-only">
                                <h4 style={{fontWeight:"500"}}>Animation</h4><br></br>
                                <div className="line"></div>
                                <h6 className="justify">Animation has world’s undivided attention. Be it films, tutorials, gaming, business promotions, animation empowers all the sectors today. Shoonyeka is the apt place for all your animation needs. Unleash your imagination with us and watch them come alive with a framework that knows no bounds.</h6>
                            </div>

                            <div className="col s12 white-text">
                                    <h4 style={{fontWeight:"500"}}>ANIMATED FEATURE</h4>
                                    <div className="line"></div>
                                    <br></br>
                            </div>
                            
                            <div className="col s12 m6">
                                <img className="responsive-img" src={require("../IMAGES/animation_1.jpg")} alt="LOADING" />
                            </div>

                            <div className="col s12 m6 white-text">
                                    <p>“To inifinity and beyond”… a line from Toy Story, first feature-length animation film, rightly describes where the animated feature films push the boundary to. Animation features is where a filmmaker’s imagination meets audience’s expectation. They have a separate category in the Oscars for making a feature length animation is no ordinary task. </p>
                            </div>

                            <div className="col s12 white-text">
                                    <p>As another feather in its cap, Shoonyeka has presented a fully animated feature-length 3D animation film on history/heritage of JSS from story-board to end product (post-production), a first of its kind film in the entire state. With that, we’ve ventured into Animation adventure. 
                                       Historical personalities or imaginary characters, Shoonyeka can bring them to life with our fully-equipped, top-of-the-line technology and, skilled and artistic team.</p>
                            </div>

                        </div>

                        <div className="row valign-wrapper">

                            <div className="col s12 white-text">
                                    <h4 style={{fontWeight:"500"}} >ARCHITECTURE DESIGN</h4>
                                    <div className="line"></div>
                                    <br></br>
                            </div>
                            
                            <div className="col s12 m6">
                                <img className="responsive-img" src={require("../IMAGES/animation_2.jpg")} alt="LOADING" />
                            </div>

                            <div className="col s12 m6 white-text">
                                    <p>Be it promotions, commercial ads, training, product demonstrations, or walk-throughs, communicating your ideas effectively in the simplest way is the key. Animation holds the key to create accurate visualization of your products that not only communicate your ideas but also captivate the viewers.</p>
                            </div>

                            <div className="col s12 white-text">
                                    <p>Shoonyeka offers interactive walk-throughs across the industries – machineries, architecture, landscapes where we design and simulate a virtually accurate portrait of your product and plans.
                                       We also have the technology and experience to provide these walk-throughs with Virtual Reality and Holography technique.</p>
                            </div>

                        </div>

                        <div className="row valign-wrapper">

                            <div className="col s12 white-text">
                                    <h4 style={{fontWeight:"500"}}>ANIMATION FOR MOVIES</h4>
                                    <div className="line"></div>
                                    <br></br>
                            </div>
                            
                            <div className="col s12 m6">
                                <img className="responsive-img" src={require("../IMAGES/animation_3.jpg")} alt="LOADING" />
                            </div>

                            <div className="col s12 m6 white-text">
                                    <p> Compositing live action with animation is the new norm in films. Take your animated characters to a whole new level of realism with our realistic, fully-functional and animated 3D/2D models that merge the line between what is real and what is virtual.</p>
                            </div>

                        </div>

                        <div className="row valign-wrapper">

                            <div className="col s12 white-text">
                                    <h4 style={{fontWeight:"500"}}>ANIMATION FOR EDUCATION</h4>
                                    <div className="line"></div>
                                    <br></br>
                            </div>
                            
                            <div className="col s12 m6">
                                <img className="responsive-img" src={require("../IMAGES/animation_4.jpg")} alt="LOADING" />
                            </div>

                            <div className="col s12 m6 white-text">
                                    <p>Animation enriches the tutorials, engages students with captivating content. It helps them better understand the concepts by giving them a new and improved learning experience. With interactive, visuals that provide engaging content, animation has been shaping a new learning experience for students. Shoonyeka crafts effective, interactive visual tools for preschool education, teacher-training and everything in between that are tailored to your needs. Shoonyeka is designing its own interactive educational digital tool for pre-school and K12.</p>
                            </div>

                        </div>
                        .
                    </div>

                </div>
        )
    }
}
