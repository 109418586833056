import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Portfolio extends Component {
    render() {
        return (
            <div style={{background: "#191919"}}>

                <div className="hide-on-small-only white-text"><img className="BANNER" src={require("../IMAGES/portfolio_lander.jpg")} alt="LOADING" /></div>
                <div className="hide-on-med-and-up white-text"><img className="BANNER" src={require("../IMAGES/portfolio_mob.jpg")} alt="LOADING" /></div>

                <Link to="/">
                    <img className="other_logo hide-on-med-and-down" src={require("../IMAGES/logo.png")} alt="LOADING" />
                    <img className="main_name hide-on-med-and-down" src={require("../IMAGES/name.png")} alt="LOADING" />
                    <img className="main_name_med hide-on-large-only show-on-medium hide-on-small-only" src={require("../IMAGES/name.png")} alt="LOADING" />
                    <img className="main_name_sm hide-on-large-only show-on-small hide-on-med-and-up" src={require("../IMAGES/name.png")} alt="LOADING" />
                </Link>


                <div className="container section center white-text row">

                    <iframe title="1" className="hide-on-med-and-down col s12" width="640" height="360" src="https://player.vimeo.com/video/439183772" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                    <iframe title="2" className="hide-on-large-only col s12" src="https://player.vimeo.com/video/439183772" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>

                    <p className="col s12 m10 offset-m1 center"><b style={{fontSize:"1rem", fontWeight:"500"}}>The Heritage of Sri Sutturu Math - An animated Feature</b><br></br><small>Production by Jagadguru Sri Veerasimhasana Mahasansthana Math (JSS), Directed by Abdul Kareem</small></p>
                </div>

                <div className="container">

                    <div className="row">
                        
                        <div className="col s12 m4 pull-m1 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="3" src="https://player.vimeo.com/video/439183915" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>         
                            <small>His Holiness Sri Shivaratri Deshikendra Mahaswamiji's Note on animated feature "The Heritage of Sri Sutturu Math'</small>
                        </div>
                        
                        <div className="col s12 m4 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="4" src="https://player.vimeo.com/video/439179893" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>   
                            <small>The making of animated feature 'The Heritage of Sri Sutturu Math'</small>
                        </div>
                        
                        <div className="col s12 m4 push-m1 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="5" src="https://player.vimeo.com/video/487512547" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>The Stall feed Goat Farming animaion presentation. Client - Yashodavana Goat Farm</small>
                        </div>
                        
                    </div>

                    <div className="row">

                        <div className="col s12 m4 pull-m1 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="6" src="https://player.vimeo.com/video/439181312" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Animation for Movie Song, Movie - Ravi History</small>
                        </div>
                        
                        <div className="col s12 m4 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="7" src="https://player.vimeo.com/video/439181350"  frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Animation for movie, Movie - Ravi history</small>
                        </div>
                        
                        <div className="col s12 m4 push-m1 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="8" src="https://player.vimeo.com/video/439179796" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Malai Madeshwara - Animated Feature, Directed by Abdul kareem, Production by Shoonyeka Solutions</small>
                        </div>
                        
                    </div>
                </div>

                <div className="container section center">
                    <div className="spacing white-text" style={{fontSize:"1.75rem", marginBottom:"1rem"}}>ARCHITECTURE DESIGN</div>
                    <div className="line" style={{marginBottom:"2rem"}}></div>

                    <div className="row">
                                                         
                        <div className="col s12 m4 pull-m1 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="9" src="https://player.vimeo.com/video/439177829" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Asian paint plant pre visualisation - Client - Srujana Media Solutions</small>
                        </div>
                                                         
                        <div className="col s12 m4 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="10" src="https://player.vimeo.com/video/487512784" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Purple Brick - Layout Walkthrough, Drona Properties</small>
                        </div>
                                                         
                        <div className="col s12 m4 push-m1 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="11" src="https://player.vimeo.com/video/439177118" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Property Walkthrough, Dhroana properties</small>
                        </div>
                    
                    </div>
                </div>

                <div className="container section">
                    <div className="spacing white-text center" style={{fontSize:"1.75rem", marginBottom:"1rem"}}>TITLE AND MOTION GRAPHICS</div>
                    <div className="line" style={{marginBottom:"2rem"}}></div>

                    <div className="row">
                        
                        <div className="col s12 m4 pull-m1 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="12" src="https://player.vimeo.com/video/439184611" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Movie Ravi history - Directed by Madhuchandra - Produced by Karthik chandra, Karnatik Films</small>
                        </div>
                        
                        <div className="col s12 m4 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="13" src="https://player.vimeo.com/video/439178536" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Movie Parchandi - Directed by Zoom Ravi - Produced by - Shivananda, Indina Enterprises</small>
                        </div>
                        
                        <div className="col s12 m4 push-m1 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="14" src="https://player.vimeo.com/video/439183707" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Movie Tarle village - directed by Raghu, Produced by - Siddegowda GBS, Jeevitha Creations</small>
                        </div>
                    
                    </div>
                </div>

                <div className="container section">
                    <div className="center spacing white-text" style={{fontSize:"1.75rem", marginBottom:"1rem"}}>EDITING</div>
                    <div className="line" style={{marginBottom:"2rem"}}></div>

                    <div className="row">
                        
                        <div className="col s12 m4 pull-m1 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="15" src="https://player.vimeo.com/video/439181147" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Movie Ravi history - Directed by Madhuchandra - Editor - Abdul kareem, Dini Virat</small>
                        </div>
                        
                        <div className="col s12 m4 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="16" src="https://player.vimeo.com/video/439181051" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Movie Parchandi - Directed by Zoom Ravi - Editor - Abdul kareem</small>
                        </div>

                        <div className="col s12 m4 push-m1 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="17" src="https://player.vimeo.com/video/439177410" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Movie Aghata - Directed by Zoom Ravi - Editor - Santhosh A G</small>
                        </div>
                    
                    </div>
                </div>

                <div className="container section">
                    <div className="center spacing white-text" style={{fontSize:"1.75rem", marginBottom:"1rem"}}>GRADING</div>
                    <div className="line" style={{marginBottom:"2rem"}}></div>

                    <div className="row">

                        <div className="col s12 m4 pull-m1 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="18" src="https://player.vimeo.com/video/439178450" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Movie Ravi history - Directed by Madhuchandra - Colorist - Prajval Urs</small>
                        </div>

                        <div className="col s12 m4 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="19" src="https://player.vimeo.com/video/439178263" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Movie Belakina Kannadi - Directed by Basavaraj Hammini - Colorist - Prajval Urs</small>
                        </div>
                    
                    </div>
                </div>

                <div className="container section">
                    <div className="center spacing white-text" style={{fontSize:"1.75rem", marginBottom:"1rem"}}>CG / VFX</div>
                    <div className="line" style={{marginBottom:"2rem"}}></div>

                    <div className="row">
                        
                        <div className="col s12 m4 pull-m1 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="21" src="https://player.vimeo.com/video/439184789" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Movie Parchandi - Directed by Zoom Ravi - Produced by - Shivananda, Indina Enterprises</small>
                        </div>
                        
                        <div className="col s12 m4 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="22" src="https://player.vimeo.com/video/439181420" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Movie Parchandi - Directed by Zoom Ravi - Produced by - Shivananda, Indina Enterprises</small>
                        </div>
                        
                        <div className="col s12 m4 push-m1 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="23" src="https://player.vimeo.com/video/439181531" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Movie Parchandi - Directed by Zoom Ravi - Produced by - Shivananda, Indina Enterprises</small>
                        </div>
                    
                    </div>

                    <div className="row">
                    
                        <div className="col s12 m4 pull-m1 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="24" src="https://player.vimeo.com/video/439178389" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Movie Ravi history - Directed by Madhuchandra - Produced by Karthik chandra, Karnatik Films</small>
                        </div>
                        
                        <div className="col s12 m4 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="25" src="https://player.vimeo.com/video/487513083" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Movie Aghata - Directed by Zoom Ravi - Produced by Prakash, Sri Sai Siddi Producitons</small>
                        </div>
                        
                        <div className="col s12 m4 push-m1 white-text center" style={{marginTop:"2rem"}}>
                            <iframe title="26" src="https://player.vimeo.com/video/439179157" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe><br></br>
                            <small>Movie Aghata - Directed by Zoom Ravi - Produced by Prakash, Sri Sai Siddi Producitons</small>
                        </div>
                    
                    </div>
                </div>

            </div>
        )
    }
}
