import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Contact extends Component {
    render() {
        return (
                <div style={{background: "#191919"}}>
                
                    <div className="hide-on-small-only white-text"><img className="BANNER" src={require("../IMAGES/contact_lander.jpg")} alt="LOADING" /></div>
                    <div className="hide-on-med-and-up white-text"><img className="BANNER" src={require("../IMAGES/contact_mob.jpg")} alt="LOADING" /></div>

                    <Link to="/">
                        <img className="main_name hide-on-med-and-down" src={require("../IMAGES/name.png")} alt="LOADING" />
                        <img className="main_name_med hide-on-large-only show-on-medium hide-on-small-only" src={require("../IMAGES/name.png")} alt="LOADING" />
                        <img className="main_name_sm hide-on-large-only show-on-small hide-on-med-and-up" src={require("../IMAGES/name.png")} alt="LOADING" />
                    </Link>

                    <div className="row container white-text section">

                        <div className="col s12 m5">
                            <h5 style={{fontWeight:"bold"}}>CONTACT US</h5>
                            <p className="hide-on-small-only" style={{marginBottom:"2rem"}}>We are here to serve you.</p>
                            <ul>
                                <li>
                                    <h6 style={{fontWeight:"bold"}}><i className="material-icons" style={{fontSize:"1rem"}}>email</i> EMAIL</h6>
                                    contact@shoonyeka.com
                                </li>
                                <li>
                                    <h6 style={{fontWeight:"bold"}}><i className="material-icons" style={{fontSize:"1rem"}}>phone</i> PHONE</h6>
                                    (+91) 7892399080
                                </li>
                                <li>
                                    <h6 style={{fontWeight:"bold"}}><i className="material-icons" style={{fontSize:"1rem"}}>book</i> ADDRESS</h6>
                                    #1530, C and D Block, kuvempunagara,
                                    <br></br>Mysore - 570023
                                </li>
                            </ul>
                            <br></br>
                            <iframe title="GOOGLE_MAP" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d974.5805737643565!2d76.62093662913865!3d12.29406899945645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf7b39ad743dcd%3A0xab20b7caae24ebca!2sShoonyeka%20Solutions%20(OPC)%20Private%20Limited!5e0!3m2!1sen!2sin!4v1593770426893!5m2!1sen!2sin" width="100%" height="300" frameBorder="0" ></iframe>
                        </div>

                        <div className="col s12 m5 offset-m2">
                            <div className="show-on-small hide-on-med-and-up"><br></br></div>
                            <h5 style={{fontWeight:"bold"}}>GET IN TOUCH</h5>
                            <p className="hide-on-small-only" style={{marginBottom:"1rem"}}>For all your queries and feedback.</p>
                            <div className="row">
                                <form className="col s12" action="contactform.php" method="post">
                                    <div className="row">
                                        <div className="input-field col s12">
                                            <i className="material-icons prefix">account_circle</i>
                                            <input id="icon_prefix" name="name" type="text" className="validate white-text" required />
                                            <label htmlFor="icon_prefix">Name</label>
                                        </div>
                                        <div className="input-field col s12">
                                            <i className="material-icons prefix">phone</i>
                                            <input id="icon_telephone" name="phone" type="tel" className="validate white-text" required/>
                                            <label htmlFor="icon_telephone">Phone</label>
                                        </div>
                                        <div className="input-field col s12">
                                            <i className="material-icons prefix">email</i>
                                            <input id="icon_email" name="email" type="email" className="validate white-text" required/>
                                            <label htmlFor="icon_email">Email</label>
                                        </div>
                                        <div className="input-field col s12">
                                            <textarea htmlFor="textarea" name="msg" className="white-text" style={{height:"200px"}} required></textarea>
                                        </div>
                                        <div className="input-field col s12">
                                            <button className="btn waves-effect waves-light orange" type="submit">SUBMIT</button>
                                        </div>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                    </div>
            .
            </div>
        )
    }
}
