import React, { Component } from 'react';

export default class Testimonials extends Component {

    componentDidMount(){
        window.M.Carousel.init(this.Carousel, {});
    }

    render() {
        return (
                <div className="valign-wrapper row center">

                    <div className="carousel col m12" style={{height:"30rem"}} ref={ Carousel => { this.Carousel = Carousel; }}>                        
                            
                            <a className="carousel-item" style={{border:'solid', borderColor:"orange", width:'20rem', height:"28rem", padding:'1rem', background:"#191919"}} href="javascript:void(0);">
                                <h6 className="white-text" style={{fontWeight:"600"}}>Srinivas Acharya</h6>
                                <h6 className="white-text" style={{fontSize:"0.75rem"}}>Yashodavana goat Farm</h6>
                                <p className="black-text white-text" style={{fontSize:'0.9rem'}}>Shoonyeka Solutions is associated with Yashodavana Goat farm since 2016 and providing service in web development, Goat Farm Information system development, Animation and other designing aspect. I appreciate their  professionalism in understanding my need and delivering the service as per my requirement.</p>
                            </a>
                            
                            <a className="carousel-item" style={{border:'solid', borderColor:"orange", width:'20rem', height:"28rem", padding:'1rem', background:"#191919"}} href="javascript:void(0);">
                                <h6 className="white-text" style={{fontWeight:"600"}}>Shivalingaiah</h6>
                                <h6 className="white-text" style={{fontSize:"0.75rem"}}>Asst. Director, Karntaka Sheep and Wool Development Corporation Ltd, Mysore</h6>
                                <p className="black-text white-text" style={{fontSize:'0.9rem'}}>Shoonyeka Solutions -  This company is very good in structural designing and 3D Solutions. In 2017-18 under the guidance of Shoonyeka Solutions, Karnataka Sheep & wool Development Corporation Ltd, Mysore designed and installed a stall at Dasara Exhibition and won the prize as “The best informative stall”</p>
                            </a>
                            
                            <a className="carousel-item" style={{border:'solid', borderColor:"orange", width:'20rem', height:"28rem", padding:'1rem', background:"#191919"}} href="javascript:void(0);">
                                <h6 className="white-text" style={{fontWeight:"600"}}>Dr. M.S.Bhaskar</h6>
                                <h6 className="white-text" style={{fontSize:"0.75rem"}}>Senior consultant Neurosurgeon Hindustani Classical vocalist</h6>
                                <p className="black-text white-text" style={{fontSize:'0.9rem'}}>I know Mr.Abdul Kareem for several years. His company designs web with many years of experience. His team is responsible for creating the web site for Swara Sankula Sangeetha Sabha(R) for which I profusely thank him. His team is very prompt, sincere and dependable in thier work. </p>
                            </a>
                            
                            <a className="carousel-item" style={{border:'solid', borderColor:"orange", width:'20rem', height:"28rem", padding:'1rem', background:"#191919"}} href="javascript:void(0);">
                                <h6 className="white-text" style={{fontWeight:"600"}}>Usha Rani</h6>
                                <h6 className="white-text" style={{fontSize:"0.75rem"}}>Director</h6>
                                <p className="black-text white-text" style={{fontSize:'0.9rem'}}>We, http://mapeoeducation.com, an overseas education consultant working in Saraswathipuram, are glad to have a good inflow of enquiries and updates on our website, designed by Mr Kareem. A quick and timely work of Kareem has helped us spread our updates about our institute on website, which got us quick responses too. I surely recommend Kareem's website designing for web advertising works.</p>
                            </a>

                            <a className="carousel-item" style={{border:'solid', borderColor:"orange", width:'20rem', height:"28rem", padding:'1rem', background:"#191919"}} href="javascript:void(0);">
                                <h6 className="white-text" style={{fontWeight:"600"}}>Umesh Badigar</h6>
                                <h6 className="white-text" style={{fontSize:"0.75rem"}}>Film Director</h6>
                                <p className="black-text white-text" style={{fontSize:'0.85rem'}}>ಸಿನಿಮಾದ ಸ್ಕ್ರಿಪ್ಟ್‌, ಶೂಟಿಂಗ್‌ ಆದ ನಂತರ ಮುಂದಿನ ಕೆಲಸವೇ ಪೋಸ್ಟ್‌ ಪ್ರೊಡಕ್ಷನ್ ವರ್ಕ್.‌ ನನ್ನ ಸಿನಿಮಾದ ವೀಡಿಯೋ ರಿಲೇಟಡ್‌ ವರ್ಕ್ಸ್‌ (ಎಡಿಟಿಂಗ್‌, ಡಿಐ, ಸಿಜಿ, ಟೈಟಲ್)‌ ಆಗಿದ್ದು ಮೈಸೂರಿನ ಶೂನ್ಯೇಕ ಸ್ಟುಡಿಯೋದಲ್ಲಿ. ಬೆಂಗಳೂರಿಗೆ ಹೋಲಿಸಿದರೆ ಮೈಸೂರಿನಲ್ಲಿ ಹೇಗೆ ಟ್ರಾಫಿ಼ಕ್‌ ಕಿರಿಕಿರಿ ಕಡಿಮೆಯೊ ಹಾಗೆಯೇ ಈ ಶೂನ್ಯೇಕ ಸ್ಟುಡಿಯೋದಲ್ಲಿಯು ಕಿರಿಕಿರಿ ಕಡಿಮೆ. ಬರಿ ಮೌನವಾಗಿ ಕೀಬೋರ್ಡ್‌ ಕುಟ್ಟುವ ಸೌಂಡ್‌ ಕೇಳಿಬರುತ್ತದೆ. ನಿರ್ದೇಶಕನ ಕರುಳುಗಳನ್ನು ಆಲಿಸುವ ತಂತ್ರಜ್ಞನ ಕಿವಿಗಳು ಸೂಕ್ಷ್ಮವಾಗಿವೆ. ಎಲ್ಲದಕ್ಕೂ ಮುಖ್ಯವಾಗಿ ಈ ಸ್ಟುಡಿಯೋದ ಮುಖ್ಯ‍ಸ್ಥರು ಮತ್ತು ಮುಖ್ಯ ತಂತ್ರಜ್ಞರು ಆಗಿರುವ ಕರೀಂ ಸರ್‌ ನನ್ನ ಫೆ಼ವರೇಟ್.‌ ನನ್ನ ಚಿತ್ರಕ್ಕೆ ರಾಜ್ಯ ಪ್ರಶಸ್ತಿದೊರೆತಿದುರಲ್ಲಿ ಈ ಸ್ಟುಡಿಯೋದ ಪಾತ್ರವು ಬಹು ಮುಖ್ಯವಾದುದು ಎಂದು ಹೇಳಲಿಕ್ಕೆ ಹೆಮ್ಮೆಯಾಗುತ್ತದೆ.</p>
                            </a>                         


                            <a className="carousel-item" style={{border:'solid', borderColor:"orange", width:'20rem', height:"28rem", padding:'1rem', background:"#191919"}} href="javascript:void(0);">
                                <h6 className="white-text" style={{fontWeight:"600"}}>Madhuchandra</h6>
                                <h6 className="white-text" style={{fontSize:"0.75rem"}}>Film Director</h6>
                                <p className="black-text white-text" style={{fontSize:'0.9rem'}}>I have worked with SHOONYAKA studio for our movie's post production. They have very sincere and hard working technical staff. They are open for changes made by Director  any number of times.. that's is their biggest USP. They never compromise on quality. Kareem, Dinesh and Prajwal used to give me valuable suggestions during post production. In the middle of number of other projects they used to concentrate on even  small corrections of our movie !!! Even big studios don't do this kind of dedicated work for their projects. If u compare with other studios I can give 5 star rating to them. Over all it was a very nice experience for me. </p>
                            </a>

                            <a className="carousel-item" style={{border:'solid', borderColor:"orange", width:'20rem', height:"28rem", padding:'1rem', background:"#191919"}} href="javascript:void(0);">
                                <h6 className="white-text" style={{fontWeight:"600"}}>Prakash Shetty</h6>
                                <h6 className="white-text" style={{fontSize:"0.75rem"}}>Apron Productions</h6>
                                <p className="black-text white-text" style={{fontSize:'0.9rem'}}>Cinema is a creative field. In the name of our production house 'Apron Productions', the word 'Apron' is associated with theatre, also a creative field. Thank u Shunyaka solutions for desiging our logo creatively and effectively.</p>
                            </a>
                            
                            <a className="carousel-item" style={{border:'solid', borderColor:"orange", width:'20rem', height:"28rem", padding:'1rem', background:"#191919"}} href="javascript:void(0);">
                                <h6 className="white-text" style={{fontWeight:"600"}}>Kartik Chandra</h6>
                                <h6 className="white-text" style={{fontSize:"0.75rem"}}>Karnatik Films</h6>
                                <p className="black-text white-text" style={{fontSize:'0.9rem'}}>Abdul Kareem has a great knowledge and taste in art and cinema , his company Shoonyaka systems is committed to make a difference to every movie they work on . They definitely made a remarkable difference to our movie RAVI HISTORY. Looking for post production work SHOONYEKA SOLUTIONS is one of the better options</p>
                            </a>
                    </div>

                </div>
        )
    }
}
