import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Team extends Component {
    render() {
        return (
            <div style={{background: "#191919"}}>
                
                <div className="hide-on-small-only white-text"><img className="BANNER" src={require("../IMAGES/team_lander.jpg")} alt="LOADING" /></div>
                <div className="hide-on-med-and-up white-text"><img className="BANNER" src={require("../IMAGES/team_mob.jpg")} alt="LOADING" /></div>
                

                <Link to="/">
                    <img className="other_logo hide-on-med-and-down" src={require("../IMAGES/logo.png")} alt="LOADING" />
                    <img className="main_name hide-on-med-and-down" src={require("../IMAGES/name.png")} alt="LOADING" />
                    <img className="main_name_med hide-on-large-only show-on-medium hide-on-small-only" src={require("../IMAGES/name.png")} alt="LOADING" />
                    <img className="main_name_sm hide-on-large-only show-on-small hide-on-med-and-up" src={require("../IMAGES/name.png")} alt="LOADING" />
                </Link>

                <div className="row center white-text section">
                    <div className="col s12 spacing" style={{fontWeight:"bold", fontSize:"1.75rem", marginBottom:"1rem"}}>ABOUT THE TEAM</div>
                    <div className="col s12 m10 offset-m1">They say “Strength of each member is the team. The strength of the team is its members”.
                    Cinema, Animation or software development is not a one-man job. It takes talent and understanding to work as a team.
                    Our teams turn our vision into action. Each member in the team contribute to the overall product. Day in, day out, our team get their head around technical challenges. These teams of people from a mix of disciplines, come together work with each other and help each other to excel and grow together. It’s their commitment, experience, skill, and patience that makes Shoonyeka an energetic and fun filled workplace.
                    The end result is something we’re proud of and you are content with.</div>
                </div>

                <div className="row center container NAMES">

                    <div className="col s12 white-text" style={{marginTop:"3rem"}}>
                        <img className="Testi_image" src={require("../IMAGES/photo01.png")} alt="LOADING" />
                        <h6 style={{fontWeight:"bold"}}>ABDUL KAREEM</h6>
                        <small>MANAGING DIRECTOR</small>
                    </div>

                    <div className="col s6 m3 white-text" style={{marginTop:"3rem"}}>
                        <img className="Testi_image" src={require("../IMAGES/photo02.png")} alt="LOADING" />
                        <h6 style={{fontWeight:"bold"}}>HEMANTH KUMAR K S</h6>
                        <small>GRAPHIC DESIGNER / VFX</small>
                    </div>

                    <div className="col s6 m3 white-text" style={{marginTop:"3rem"}}>
                        <img className="Testi_image" src={require("../IMAGES/photo03.png")} alt="LOADING" />
                        <h6 style={{fontWeight:"bold"}}>DINESH G R</h6>
                        <small>EDITOR / ANIMATOR</small>
                    </div>

                    {/* <div className="col s6 m3 white-text" style={{marginTop:"3rem"}}>
                        <img className="Testi_image" src={require("../IMAGES/photo04.png")} alt="LOADING" />
                        <h6 style={{fontWeight:"bold"}}>PRITHVIRAJ NAIK</h6>
                        <small>TECHNICAL DIRECTOR</small>
                    </div> */}

                    <div className="col s6 m3 white-text" style={{marginTop:"3rem"}}>
                        <img className="Testi_image" src={require("../IMAGES/photo05.png")} alt="LOADING" />
                        <h6 style={{fontWeight:"bold"}}>SUMAN B</h6>
                        <small>3D CHARACTER MODELING ARTIST</small>
                    </div>

                    <div className="col s6 m3 white-text" style={{marginTop:"3rem"}}>
                        <img className="Testi_image" src={require("../IMAGES/photo06.png")} alt="LOADING" />
                        <h6 style={{fontWeight:"bold"}}>SHIVASHANKARA D S</h6>
                        <small>DYNAMICS ARTIST</small>
                    </div>

                    <div className="col s6 m3 white-text" style={{marginTop:"3rem"}}>
                        <img className="Testi_image" src={require("../IMAGES/photo07.png")} alt="LOADING" />
                        <h6 style={{fontWeight:"bold"}}>SANTHOSH A G</h6>
                        <small>EDITOR / GRADING ARTIST</small>
                    </div>

                    <div className="col s6 m3 white-text" style={{marginTop:"3rem"}}>
                        <img className="Testi_image" src={require("../IMAGES/photo08.png")} alt="LOADING" />
                        <h6 style={{fontWeight:"bold"}}>SATHISH R</h6>
                        <small>TECHNICAL COORDINATOR</small>
                    </div>

                    <div className="col s6 m3 white-text" style={{marginTop:"3rem"}}>
                        <img className="Testi_image" src={require("../IMAGES/photo09.png")} alt="LOADING" />
                        <h6 style={{fontWeight:"bold"}}>SAUGANDH</h6>
                        <small>STORYBOARD / CONCEPT ARTIST</small>
                    </div>

                    <div className="col s6 m3 white-text" style={{marginTop:"3rem"}}>
                        <img className="Testi_image" src={require("../IMAGES/photo10.png")} alt="LOADING" />
                        <h6 style={{fontWeight:"bold"}}> VEEKSHIT V</h6>
                        <small>Jr MODELING ARTIST</small>
                    </div>

                    <div className="col s6 m3 white-text" style={{marginTop:"3rem"}}>
                        <img className="Testi_image" src={require("../IMAGES/photo11.png")} alt="LOADING" />
                        <h6 style={{fontWeight:"bold"}}> PRASANNA KUMAR</h6>
                        <small>SYSTEM ADMIN</small>
                    </div>

                    <div className="col s6 m3 white-text" style={{marginTop:"3rem"}}>
                        <img className="Testi_image" src={require("../IMAGES/photo12.png")} alt="LOADING" />
                        <h6 style={{fontWeight:"bold"}}>PRABHU SWAMY</h6>
                        <small>PROJECT LEAD</small>
                    </div>

                    <div className="col s6 m3 white-text" style={{marginTop:"3rem"}}>
                        <img className="Testi_image" src={require("../IMAGES/photo13.png")} alt="LOADING" />
                        <h6 style={{fontWeight:"bold"}}>PAVAN SHANKAR</h6>
                        <small>DEVELOPER</small>
                    </div>

                </div>
                .
            </div>
        )
    }
}
