import React from 'react';
import './App.css';
import { HashRouter, Route } from 'react-router-dom';
import Home from './HOME/Home';
import Navbar from './GENERAL/Navbar';
import Footer from './GENERAL/Footer';
import Animation from './ANIMATION/Animation';
import Cinema from './CINEMA/Cinema';
import Software from './SOFTWARE/Software';
import Contact from './CONTACT/Contact';
import Team from './TEAM/Team';
import Portfolio from './PORTFOLIO/Portfolio';
import ScrollToTop from './GENERAL/ScrollToTop';

function App() {
  return (
        <HashRouter>
              <div className="App">
                <Navbar />
                <Route path='/' component={ScrollToTop} />
                <Route exact path='/' component={Home} />
                <Route path='/animation' component={Animation} />
                <Route path='/cinema' component={Cinema} />
                <Route path='/software' component={Software} />
                <Route path='/portfolio' component={Portfolio} />
                <Route path='/team' component={Team} />
                <Route path='/contact' component={Contact} />
                <Footer />
              </div>
        </HashRouter>
  );
}

export default App;
