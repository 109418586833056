import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Cinema extends Component {
    render() {
        return (
                <div style={{background: "#191919"}}>
                    <div className="vimeo-wrapper" >
                        <iframe style={{width:"100%"}} title="CINEMA_VIDEO" src="https://player.vimeo.com/video/432509207?background=1" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen allow="autoplay"></iframe>
                    </div>

                    <Link to="/">
                        <img className="other_logo hide-on-med-and-down" src={require("../IMAGES/logo.png")} alt="LOADING" />
                        <img className="main_name hide-on-med-and-down" src={require("../IMAGES/name.png")} alt="LOADING" />

                        <img className="main_name_med hide-on-large-only show-on-medium hide-on-small-only" src={require("../IMAGES/name.png")} alt="LOADING" />

                        <img className="main_name_sm hide-on-large-only show-on-small hide-on-med-and-up" src={require("../IMAGES/name.png")} alt="LOADING" />
                    </Link>

                    <div className="ani_disp roboto_med hide-on-med-and-down white-text">
                        <h4>CINEMA</h4>
                        <div className="line"></div>
                        <p>Cinema, without a doubt, is a collaborative art. Through all stages of film-making – pre-production, production, post-production, professionals from varied arts and technologies contribute their expertise to the film. Bit by bit, each such contribution make a film, like music from various instruments playing notes of the same song make a symphony.</p>
                    </div>

                    <div className="roboto container line_change" style={{paddingTop:"1rem"}}>
                        
                        <div className="row valign-wrapper">

                            <div className="col s12 white-text hide-on-large-only">
                                <h4 style={{fontWeight:"500"}}>Cinema</h4>
                                <div className="line"></div>
                                <p>Cinema, without a doubt, is a collaborative art. Through all stages of film-making – pre-production, production, post-production, professionals from varied arts and technologies contribute their expertise to the film. Bit by bit, each such contribution make a film, like music from various instruments playing notes of the same song make a symphony.</p>
                            </div>

                            <div className="col s12 white-text">
                                    <h4 style={{fontWeight:"500"}}>PRE - PRODUCTION</h4>
                                    <div className="line"></div>
                                    <br></br>
                            </div>

                            <div className="col s12 white-text">
                                <ul className="justify">
                                    <p> As pre-production partner we help bring your ideas and stories on paper and from paper to storyboard, animatic and help you visualize the film before it goes to production. Shoonyeka provides everything necessary to convey your stories, and how the story 	world looks like to producers, artists alike such as storyboards, concept art, animatic and previs.</p>
                                    <p> We craft and sketch the perfect storyboards for all sorts of creators – filmmakers, cartoonists, and animators etc. that you can see visualize, move around and brainstorm to find the perfect ideas that work for you.</p>
                                </ul>
                            </div>

                            <div className="col s12 m12 l6">
                                <img className="responsive-img" src={require("../IMAGES/cinema_1.jpg")} alt="LOADING" />
                            </div>

                            <div className="col s12 m12 l6 white-text">
                                <ul>
                                    <li> We design concept art where we illustrate characters, props, landscapes imaginary or real so that you know the look and feel of your story. You remain the architect while we create your virtual world of fantasy.</li><br></br>
                                    <li> Test the effectiveness of your commercial ideas with our animatic well before it is produced.</li><br></br>
                                    <li> We provide detailed Previs (pre-visualization) to help directors determine scene blocking, make effective shot composition and creative decisions.</li>
                                </ul>
                            </div>

                        </div>

                        <div className="row valign-wrapper">

                            <div className="col s12 white-text">
                                    <h4 style={{fontWeight:"500"}} >POST - PRODUCTION</h4>
                                    <div className="line"></div>
                                    <br></br>
                                    <p>Shoonyeka, though now exploring new horizons, originally began as a post-production studio for feature films. Today, we provide post-production for everyone in the video making community – feature films, documentaries, album songs, fight editing, vlogs, series, and, short films. With four years of experience in all its aspects, we are your perfect post production partner and we offer all post-production services under one roof/ in one place.</p>
                                    <br></br>
                            </div>
                            
                            <div className="col s12 m6">
                                <img className="responsive-img" src={require("../IMAGES/cinema_2.jpg")} alt="LOADING" />
                            </div>

                            <div className="col s12 m6 white-text">
                                    <h5 style={{fontWeight:"500"}} >EDITING</h5>
                                    <p>1000s of video footages to one film is a lot like reading an entire textbook and writing exam”- Our editor often jokes. Editing is both creative and a technical task. When done right, editing makes your videos speak for themselves. We understand the rhyme and rhythm of editing and weave your film to narrate the story that you want them to. Let our experience serve in transforming all your footage to one meaningful film.</p>
                            </div>

                        </div>

                        <div className="row valign-wrapper">
                            
                            <div className="col s12 m6 push-m6">
                                <img className="responsive-img" src={require("../IMAGES/cinema_3.jpg")} alt="LOADING" />
                            </div>

                            <div className="col s12 m6 pull-m6 white-text">
                                    <h5 style={{fontWeight:"500"}} >CG / VFX</h5>
                                    <p>Add to the beauty and impact of a film. VFX can transform a dull empty scene to an powerful, action packed scene that gets your audience on the edge of their seat. We create all the visual effects that believable yet impossible for the camera to capture taking your film to a whole new level of realism and production value.</p>
                            </div>

                        </div>

                        <div className="row valign-wrapper">
                            
                            <div className="col s12 m6">
                                <img className="responsive-img" src={require("../IMAGES/cinema_4.jpg")} alt="LOADING" />
                            </div>

                            <div className="col s12 m6 white-text">
                                    <h5 style={{fontWeight:"500"}} >GRADING</h5>
                                    <p>Colors and emotions walk hand-in-hand for each color evokes different emotions. Our experience and latest technology with high color accuracy ensures that your videos are color-corrected and color- graded to precisely portray the emotion and theme that best suits your movie. The end result will be consistent and enhanced cinematic experience.</p>
                            </div>

                        </div>

                        <div className="row valign-wrapper">
                            
                            <div className="col s12 m6 push-m6">
                                <img className="responsive-img" src={require("../IMAGES/cinema_5.jpg")} alt="LOADING" />
                            </div>

                            <div className="col s12 m6 pull-m6 white-text">
                                    <h5 style={{fontWeight:"500"}} >MOTION GRAPHICS</h5>
                                    <p>Title design has evolved into an art form of its own. Our highly skilled motion graphic designers create appealing graphic artworks that captivate your audience. We design dynamic titles for movies that carry the theme, set a mood and capture your audience before the movie even begins.</p>
                            </div>

                        </div>

                       .
                    </div>

                </div>
        )
    }
}
