import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Navbar extends Component {

    componentDidMount(){

        const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
            var elems = document.querySelectorAll('.sidenav');
            M.Sidenav.init(elems);
          });
    }

    render() {
        return (
            <nav>
                <div className="nav-wrapper" style={{background: "#151515"}}>
                    <div className="brand-logo center roboto">
                        <ul className="hide-on-med-and-down">
                            <li style={{paddingRight:"2rem"}} className="spacing"><Link to="/animation">ANIMATION</Link></li>
                            <li style={{paddingRight:"2rem"}} className="spacing"><Link to="/cinema">CINEMA</Link></li>
                            <li style={{paddingRight:"2rem"}} className="spacing"><Link to="/software">SOFTWARE</Link></li>
                        </ul>
                    </div>
                    <ul className="right hide-on-med-and-down" style={{marginRight:"2rem"}}>
                        <li><Link to="/portfolio">PORTFOLIO</Link></li>
                        <li><Link to="/team">TEAM</Link></li>
                        <li><Link to="/contact">CONTACT</Link></li>
                    </ul>
                    <a href="#!" data-target="mobile-nav" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                </div>

                <ul id="mobile-nav" className="sidenav">
                        <li className="sidenav-close"><Link to="/"><i className="material-icons prefix">home</i>HOME</Link></li>
                        <li className="sidenav-close"><Link to="/animation"><i className="material-icons prefix">font_download</i>ANIMATION</Link></li>
                        <li className="sidenav-close"><Link to="/cinema"><i className="material-icons prefix">movie</i>CINEMA</Link></li>
                        <li className="sidenav-close"><Link to="/software"><i className="material-icons prefix">devices</i>SOFTWARE</Link></li>
                        <li className="sidenav-close"><Link to="/portfolio"><i className="material-icons prefix">developer_board</i>PORTFOLIO</Link></li>
                        <li className="sidenav-close"><Link to="/team"><i className="material-icons prefix">group</i>TEAM</Link></li>
                        <li className="sidenav-close"><Link to="/contact"><i className="material-icons prefix">contacts</i>CONTACT</Link></li>
                </ul>
            </nav>
        )
    }
}
