import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Software extends Component {
    render() {
        return (
            <div style={{background: "#191919"}}>

                <div className="hide-on-small-only white-text"><img className="BANNER" src={require("../IMAGES/software_lander.jpg")} alt="LOADING" /></div>
                <div className="hide-on-med-and-up white-text"><img className="BANNER" src={require("../IMAGES/software_mob.jpg")} alt="LOADING" /></div>

                <Link to="/">
                    <img className="other_logo hide-on-med-and-down" src={require("../IMAGES/logo.png")} alt="LOADING" />
                    <img className="main_name hide-on-med-and-down" src={require("../IMAGES/name.png")} alt="LOADING" />
                    <img className="main_name_med hide-on-large-only show-on-medium hide-on-small-only" src={require("../IMAGES/name.png")} alt="LOADING" />
                    <img className="main_name_sm hide-on-large-only show-on-small hide-on-med-and-up" src={require("../IMAGES/name.png")} alt="LOADING" />
                </Link>

                <div className="section roboto container line_change">

                        <div className="row valign-wrapper">

                            <div className="col s12 white-text center">
                                    <h4 style={{fontWeight:"500"}}>SOFTWARE DEVELOPMENT</h4>
                                    <div className="line"></div>
                                    <br></br>
                            </div>

                            <div className="col s12 white-text center">

                                <ul>
                                    <li> Shoonyeka is an expert in web and mobile technologies. So far, it has developed several web applications and android applications for various organizations and individuals tailored to their needs. </li><br></br>
                                </ul>
                                
                            </div>

                        </div>

                        <div className="row valign-wrapper">
                        
                            <div className="col s12 m6">
                                <img className="responsive-img" src={require("../IMAGES/software_1.jpg")} alt="LOADING" />
                            </div>
                            <div className="col s12 m6 white-text">
                                    <h5 style={{fontWeight:"500"}} >OUR MAJOR PROJECTS</h5>
                                    <div className="line"></div>
                                    <ol>
                                        <li> Educational institute management service</li>
                                        <li> Online CET preparation application for engineering and medical aspirants</li>
                                        <li> Internal management application for a crusher plant</li>
                                    </ol>
                            </div>

                        </div>

                        <div className="row valign-wrapper section_2">
                        
                            <div className="col s12 m6 push-m6">
                                <img className="responsive-img" src={require("../IMAGES/software_2.jpg")} alt="LOADING" />
                            </div>
                            <div className="col s12 m6 pull-m6 white-text">
                                    <h5 style={{fontWeight:"500"}} >OUR PIPELINE PROJECTS</h5>
                                    <div className="line"></div>
                                    <ol>
                                        <li> A Complete MIS for Stall Feeding Goat Farm with E-commerce application</li>
                                        <li> Management information system for Bio Gas products</li>
                                        <li> A CSM for Real estate Firm</li>
                                        <li> E-commerce application/portal and more</li>
                                    </ol>
                            </div>

                        </div>

                        <div className="row valign-wrapper section_2">
                        
                            <div className="col s12 m6">
                                <img className="responsive-img" src={require("../IMAGES/software_3.jpg")} alt="LOADING" />
                            </div>
                            <div className="col s12 m6 white-text">
                                    <h5 style={{fontWeight:"500"}} >THE TECHNICAL PLATFORM</h5>
                                    <div className="line"></div>
                                    <ol>
                                        <li> Backend - Microsoft’s ASP.Net Frame work, C#.</li>
                                        <li> DB - SQL server, MySQL, NoSQL.</li>
                                        <li> Frontend - HTML/CSS/JS, Angular JS, React JS.</li>
                                    </ol>
                            </div>

                        </div>

                        <div className="row valign-wrapper section_2">
                        
                            <div className="col s12 m6 push-m6">
                                <img className="responsive-img" src={require("../IMAGES/software_4.jpg")} alt="LOADING" />
                            </div>
                            <div className="col s12 m6 pull-m6 white-text">
                                    <h5 style={{fontWeight:"500"}} >ABOUT DIGITAL EDUCATION TOOL</h5>
                                    <div className="line"></div>
                                    <p>Shoonyeka is bringing out educational learning tools, both online and offline. These digital learning tools will have visually rich content that provide interactive and engaging learning experience to students of all age. Students will learn more by</p>
                            </div>
                            <div className="col s12 white-text justify">                                    
                                    <ol>
                                        <li> Creating virtual labs for science concepts.</li>
                                        <li> Latest technologies like Touch screen interaction, Augmented Reality, Virtual Reality and Holographic display tools can be incorporated in developing digital learning tools.</li>
                                        <li> Animating the history studies.</li>
                                        <li> Developing lesson plans for language learning which improves grammar, phonetics etc., through sound interactive interfaces
                                             These projects take the power of softwares, multimedia, and gaming technologies to be built. Shoonyeka is experienced with these platforms and capable of developing digital learning tools as desired.</li>
                                    </ol>
                            </div>

                        </div>
                        
                </div>

            </div>
        )
    }
}
