import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Footer extends Component {

    scrollTop()
    {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    render() {
        return (
                <footer className="page-footer center" style={{backgroundColor:'#151515'}}>
                    <div className="container">
                        <br></br><br></br>
                        <div>
                            <a href="https://mobile.twitter.com/shoonyekal" rel="noopener noreferrer" target="_blank"><img className="responsive-img" style={{  height: '1.5rem', paddingRight: '0.5rem'}} src={require("../IMAGES/twitter.png")} alt="LOADING" /></a>
                            <a href="https://www.facebook.com/Shoonyeka-Solutions-103483694770892/?modal=admin_todo_tour" rel="noopener noreferrer" target="_blank"><img className="responsive-img" style={{  height: '1.5rem', paddingRight: '0.5rem'}} src={require("../IMAGES/facebook.png")} alt="LOADING" /></a>
                            <a href="https://www.instagram.com/shoonyeka.mysore2020/" rel="noopener noreferrer" target="_blank"><img className="responsive-img" style={{  height: '1.5rem', paddingRight: '0.5rem'}} src={require("../IMAGES/instagram.png")} alt="LOADING" /></a>
                            <a href="https://www.youtube.com/channel/UClsdfIsyAquKMEa82hE49Gw?view_as=subscriber" rel="noopener noreferrer" target="_blank"><img className="responsive-img" style={{  height: '1.5rem', paddingRight: '0.5rem'}} src={require("../IMAGES/youtube.png")} alt="LOADING" /></a>
                        </div>
                        <br></br>
                        </div>
                            <div className="row">
                                <div className="line col s6 offset-s3 m4 offset-m4" style={{marginBottom:"1rem"}}></div>
                                <div className="col s12 hide-on-med-and-down">
                                    <Link to="/" className="FOOTER_ITEMS">HOME</Link>
                                    <Link to="/animation" className="FOOTER_ITEMS">ANIMATION</Link>
                                    <Link to="/cinema" className="FOOTER_ITEMS">CINEMA</Link>
                                    <Link to="/software" className="FOOTER_ITEMS">SOFTWARE</Link>
                                    <Link to="/portfolio" className="FOOTER_ITEMS">PORTFOLIO</Link>
                                    <Link to="/team" className="FOOTER_ITEMS">TEAM</Link>
                                    <Link to="/contact" className="FOOTER_ITEMS">CONTACT</Link>
                                </div>
                                <div className="col s12 hide-on-large-only">
                                    <Link to="/" className="FOOTER_ITEMS_MED">HOME</Link>
                                    <Link to="/animation" className="FOOTER_ITEMS_MED">ANIMATION</Link>
                                    <Link to="/cinema" className="FOOTER_ITEMS_MED">CINEMA</Link>
                                    <Link to="/software" className="FOOTER_ITEMS_MED">SOFTWARE</Link>
                                    <Link to="/portfolio" className="FOOTER_ITEMS_MED">PORTFOLIO</Link><br></br>
                                    <Link to="/team" className="FOOTER_ITEMS_MED">TEAM</Link>
                                    <Link to="/contact" className="FOOTER_ITEMS_MED">CONTACT</Link>
                                </div>
                        </div>
                    <a href="javascript:void(0);" className="white-text"><span style={{fontSize:'4rem'}} className="material-icons" onClick={this.scrollTop}>keyboard_arrow_up</span></a>
                </footer>
        )
    }
}
