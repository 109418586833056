import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Testimonials from './Testimonials';

export default class Home extends Component {
    render() {
        return (
            <div style={{background: "#191919"}}>
                
                    <div className="vimeo-wrapper" >
                        <iframe style={{width:"100%"}} title="HOME_VIDEO" src="https://player.vimeo.com/video/432507996?background=1" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen allow="autoplay"></iframe>
                    </div>                                                                   
                    
                    <img className="main_logo hide-on-med-and-down" src={require("../IMAGES/logo.png")} alt="LOADING" />
                    <img className="main_name hide-on-med-and-down" src={require("../IMAGES/name.png")} alt="LOADING" />

                    <img className="main_name_med hide-on-large-only show-on-medium hide-on-small-only" src={require("../IMAGES/name.png")} alt="LOADING" />
                    <img className="main_logo_med hide-on-large-only show-on-medium hide-on-small-only" src={require("../IMAGES/logo.png")} alt="LOADING" />

                    <img className="main_name_sm hide-on-large-only show-on-small hide-on-med-and-up" src={require("../IMAGES/name.png")} alt="LOADING" />
                    <img className="main_logo_sm hide-on-large-only show-on-small hide-on-med-and-up" src={require("../IMAGES/logo.png")} alt="LOADING" />

                    <div className="row section main_links roboto_med hide-on-med-and-down">
                        
                        <div className="col right m3 offset-m2">
                            <Link to="/software" className="white-text">
                                <h4 style={{textAlign:"right"}}>SOFTWARE</h4>
                                <div className="line"></div>
                                <h6 style={{textAlign:"right"}}>Where real life problems meet digital solutions</h6>
                            </Link>
                        </div>

                        <div className="col m3">
                            <Link to="/animation" className="white-text">
                                <h4>ANIMATION</h4>
                                <div className="line"></div>
                                <h6>Next step in entertainment, education and experience</h6>
                            </Link>
                        </div>
                        
                        <div className="col center m4 push-m1">
                            <Link to="/cinema" className="white-text">
                                <h4>CINEMA</h4>
                                <div className="line"></div>
                                <h6>A symphony of all arts and technology in Harmony</h6>
                            </Link>
                        </div>
                    </div>

                    <div className="section roboto container line_change">

                        <div className="row valign-wrapper">
                            <div className="col s12 m6 push-m6">
                                <img className="responsive-img" src={require("../IMAGES/about.jpg")} alt="LOADING" />
                            </div>

                            <div className="col s12 m6 pull-m6 white-text">
                                    <h6 style={{fontWeight:"500"}}>ABOUT</h6>
                                    <div className="line"></div>
                                    <p className="justify">Shoonyeka Solutions (OPC) Pvt. Ltd. is engaged with multiple interrelated platforms – developing web apps, Android/Mobile apps, and desktop apps, animation, Game development, Post-production for Feature films and creative ads - Editing, Visual Effects, and color grading. We also provide consultancy services for making feature films, and personality development.</p>
                            </div>
                        </div>

                        <div className="row valign-wrapper">
                            <div className="col s12 m6">
                                <img className="responsive-img" src={require("../IMAGES/vision.jpg")} alt="LOADING" />
                            </div>

                            <div className="col s12 m6 white-text">
                                    <h6 style={{fontWeight:"500"}}>VISION</h6>
                                    <div className="line"></div>
                                    <p className="justify">Be the best platform for innovative, and passionate native artists and technicians dedicated and focused on developing digital entertainment and education products that meet the global standards and reach the global audience, teachers and pupils.</p>
                            </div>
                        </div>

                        <div className="row valign-wrapper">
                            <div className="col s12 m6 push-m6">
                                <img className="responsive-img" src={require("../IMAGES/mission.jpg")} alt="LOADING" />
                            </div>

                            <div className="col s12 m6 pull-m6 white-text">
                                    <h6 style={{fontWeight:"500"}}>MISSION</h6>
                                    <div className="line"></div>
                                    <p className="justify">Facilitate an expanded array of potentialities by creating animation and gaming products, developing applications, and providing the post-production services of the highest standards.This future-ready product enhances learning capabilities as well as knowledge acquiring. </p>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                            <div className="col s12 white-text center"><h5 style={{fontWeight:"700", marginBottom:"4rem", letterSpacing:"0.25rem"}}>TESTIMONIALS</h5></div>
                            <div className="col s12"><Testimonials/></div>
                    </div>

                    <div className="col s12 white-text center"><h5 style={{fontWeight:"700", marginBottom:"2rem", letterSpacing:"0.25rem"}}>OUR CLIENTS</h5></div>
                    <div className="row grey lighten-2" style={{paddingTop:"2rem"}}>
                            <div className="container">
                                <div className="col s12 m4">
                                    <div className="row center">
                                        <div className="col s12 black-text center">
                                            <h6 style={{fontWeight:"bold", marginBottom:"1.5rem", letterSpacing:"0.1rem"}}>ANIMATION</h6>
                                            <div className="line"></div>
                                            <br></br>
                                        </div>
                                        <div className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_1.png")} alt="LOADING" /></div>
                                        <div className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_2.png")} alt="LOADING" /></div>
                                        <a href="http://www.yashodavanagoatfarm.com/" rel="noopener noreferrer" target="_blank" className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_3.png")} alt="LOADING" /></a>
                                        <a href="http://kintssolar.com/" rel="noopener noreferrer" target="_blank" className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_4.png")} alt="LOADING" /></a>
                                        <div className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_5.png")} alt="LOADING" /></div>
                                        <div className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_6.png")} alt="LOADING" /></div>
                                    </div>
                                </div>
                                <div className="col s12 m4">
                                    <div className="row center">
                                        <div className="col s12 black-text center">
                                            <h6 style={{fontWeight:"bold", marginBottom:"1.5rem", letterSpacing:"0.1rem"}}>CINEMA</h6>
                                            <div className="line"></div>
                                            <br></br>
                                        </div>
                                        <div className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_7.png")} alt="LOADING" /></div>
                                        <div className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_8.png")} alt="LOADING" /></div>
                                        <div className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_9.png")} alt="LOADING" /></div>
                                        <div className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_10.png")} alt="LOADING" /></div>
                                        <div className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_11.png")} alt="LOADING" /></div>
                                        <div className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_12.png")} alt="LOADING" /></div>
                                    </div>
                                </div>
                                <div className="col s12 m4">
                                    <div className="row center">
                                        <div className="col s12 black-text center">
                                            <h6 style={{fontWeight:"bold", marginBottom:"1.5rem", letterSpacing:"0.1rem"}}>SOFTWARE</h6>
                                            <div className="line"></div>
                                            <br></br>
                                        </div>
                                        <a href="https://www.uni-mysore.ac.in/" rel="noopener noreferrer" target="_blank" className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_13.png")} alt="LOADING" /></a>
                                        <a href="https://mastercetweb.azurewebsites.net/" rel="noopener noreferrer" target="_blank" className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_14.png")} alt="LOADING" /></a>
                                        <a href="http://www.yashodavanagoatfarm.com/" rel="noopener noreferrer" target="_blank" className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_15.png")} alt="LOADING" /></a>
                                        <a href="http://zioxphotography.com/" rel="noopener noreferrer" target="_blank" className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_16.png")} alt="LOADING" /></a>
                                        <a href="https://www.pragathiorganics.com/" rel="noopener noreferrer" target="_blank" className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_17.png")} alt="LOADING" /></a>
                                        <a href="https://swarasankula.org/" rel="noopener noreferrer" target="_blank" className="col s4"><img className="responsive-img" style={{width:"100%"}} src={require("../IMAGES/logo_18.png")} alt="LOADING" /></a>
                                    </div>
                                </div>
                            </div>
                    </div>
.                               
            </div>
        )
    }
}
